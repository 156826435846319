// src/components/DynamicContent.js
import React, { useEffect, useState } from 'react';
import { useProjectName } from '../ProjectNameContext';
import './DynamicContent.css';

function DynamicContent({ contentName }) {
  


  return (
    <div className="dynamic-content">
    </div>
  );
}

export default DynamicContent;
