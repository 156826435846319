import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <div className="footer">

      <p>Adimari Studio ltd.</p>

    </div>

  );
}

export default Footer;